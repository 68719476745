'use strict';

var React = require("react");
var Layout = require("../components/Layout.js");
var Article = require("../components/Article.js");
var ReactHelmet = require("react-helmet");

function PostPageTemplate(Props) {
  var pageContext = Props.pageContext;
  var data = Props.data;
  var markdownRemark = data.markdownRemark;
  var id = markdownRemark.id;
  var html = markdownRemark.html;
  var frontmatter = markdownRemark.frontmatter;
  return React.createElement(Layout.make, {
              children: React.createElement(Article.make, {
                    id: id,
                    frontmatter: frontmatter,
                    pageContext: pageContext,
                    children: null
                  }, React.createElement(ReactHelmet.Helmet, {
                        title: frontmatter.title
                      }), React.createElement("div", {
                        dangerouslySetInnerHTML: {
                          __html: html
                        }
                      }))
            });
}

var make = PostPageTemplate;

exports.make = make;
/* react Not a pure module */
